import React from 'react';
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import TeamCard, { TeamProps } from "./components/TeamCard";

const images = [
    "/images/meta.png",
    "/images/google.png",
    "/images/tu.png",
    "/images/nbcUniversal.png",
    "/images/oracle.png",
    "/images/pwd.png",
    "/images/gc.png",
    "/images/amex.png",
    "/images/disney.png",
    "/images/ycom.png"
];

const teams: TeamProps[] = [
    {
        name: "Ved Prakash",
        position: "Founder and CEO",
        imgSrc: "/images/us1.jpg",
        linkedIn: "https://www.linkedin.com/in/ved-prakash-sheoran-2a774818",
        education: [
            "B.Tech - NIT Trichy",
            "MBA - Michigan State",
            "MPA - Columbia University"
        ],
        experience: [
            "Head of Marketing Science (Agencies) at Meta",
            "Manager at PwC",
            "Data Science lead at EXL, WNS, and Mu Sigma"
        ],
        speciality: ["Ad-Tech", "Data Science", "Consulting"]
    },
    {
        name: "Chase Mohney",
        position: "Sales & Partnership",
        imgSrc: "/images/chase.jpg", // Note: Ensure the image source is correct
        linkedIn: "https://www.linkedin.com/in/chasemohney",
        education: ["BA - Harvard University"],
        experience: [
            "Agency Sales & Emerging Tech at Meta",
            "Cofounder at Trilogy Interactive",
            "Political Campaign Marketing at Blackrock"

        ],
        speciality: [
            "Partnership & Sales",
            "Innovation",
            "Marketing"
        ]
    },
    {
        name: "Chris Keswani",
        position: "Head of Business Development",
        imgSrc: "/images/chris.png",
        linkedIn: "https://www.linkedin.com/in/chriskeswani",
        education: ["BS Marketing, Boston College"],
        experience: [
            "Product Marketing Manager at Meta",
            "Marketing & Business Development at Forest Interactive",
            "Account Executive at multiple start-ups"
        ],
        speciality: ["Business Development", "Marketing", "Partnerships"]
    },
    {
        name: "Pratik Gurukar",
        position: "Lead Engineer",
        imgSrc: "/images/pratik.jpg", // Note: Ensure the image source is correct
        linkedIn: "https://www.linkedin.com/in/pratik-gurukar",
        education: ["B.Tech - Vishwakarma Institute of Technology"],
        experience: [
            "Founder at TradersOps",
            "Senior Developer at Jiraaf",
            "Engineer at Radix Health"
        ],
        speciality: [
            "Full Stack Development",
            "Agile & Lean Methodologies",
            "Leadership & Team Management"
        ]
    },
    {
        name: "Beau Avril",
        position: "Advisor",
        imgSrc: "/images/beau.png",
        linkedIn: "https://www.linkedin.com/in/beauavril",
        education: ["BBA, Cornell University"],
        experience: [
            "Director of Product Marketing at Meta",
            "Director of Business Operations at Google",
            "Board director at IAB"
        ],
        speciality: ["Strategy", "Product", "Partnerships"]
    },
    // {
    //     name: "Ashok Dhakar",
    //     position: "Chief Technical Advisor",
    //     imgSrc: "/images/t4.jpg",
    //     linkedIn: "https://www.linkedin.com/in/ashok-dhakar-3527794",
    //     education: ["B.Tech - NIT Trichy"],
    //     experience: [
    //         "Founding Engineer at Idemeum",
    //         "Lead Engineer at VMWare, Oracle, and Adobe"
    //     ],
    //     speciality: ["Identity & Privacy", "Cloud Platforms", "Database - SQL and NoSQL"]
    // },
    {
        name: "Aaron Radin",
        position: "Advisor",
        imgSrc: "/images/aaron.png",
        linkedIn: "https://www.linkedin.com/in/aaronradin",
        education: ["BA - New York University", "MBA - Columbia University"],
        experience: [
            "CEO at British Basketball League",
            "Board member in Brooklyn Community Services",
            "Director at Meta",
            "Senior leadership roles at Disney, CBS and NBCUniversal",
            "CEO & Founder at Toura"
        ],
        speciality: ["Media", "Technology", "Consulting"]
    },

];

const Teams: React.FC = () => {
    return (
        <>
            <Navbar />
            <div className={"text-center my-8 text-4xl font-extrabold text-primary"}>Adrsta AI TEAM</div>
            <div className={"text-center my-12 text-3xl font-bold text-secondary"}>Our team consists of experts and
                practitioners of AdTech, AI, and Privacy
            </div>

            <div className={"flex flex-wrap justify-center my-20 sm:mx-32 mx-8"}>
                {images.map((image, index) => (
                    <div key={index} className="flex flex-col justify-center mt-12 sm:ml-16 ml-0"
                        style={{ width: '200px' }}>
                        <img src={image} alt={`Brand ${index}`} />
                    </div>
                ))}
            </div>
            <div className={"text-center font-extrabold text-2xl text-gray-500 sm:mx-32 mx-8 leading-8"}
                style={{ lineHeight: '40px' }}>
                As practitioners of Ad-tech, Data Science and Privacy, our founding team is brough together by a shared vision of brining greater efficiency in Advertising using data and AI
            </div>
            <div className={"flex flex-wrap justify-center my-20 mx-32"}>
                {teams.map((team, index) => (
                    <div key={index} className="flex flex-col justify-center mt-12 ml:8 sm:ml-16"
                        style={{ width: '500px' }}>
                        <TeamCard name={team.name} imgSrc={team.imgSrc} position={team.position}
                            linkedIn={team.linkedIn} experience={team.experience} speciality={team.speciality}
                            education={team.education} />
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
};

export default Teams;
